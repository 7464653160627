@import "../base.scss";

.earth .site-footer-e {
	
	&.all-links {
		@include media-down(sm) {
			margin: 10px 0 0;
		}
		span {
			color: $c-text-secondary;
			span .app-logo,
			a .app-logo {
				width: 120px;
				height: 51px;
			}
			@include media-down('md') {
				span .app-logo,
				a .app-logo {
					width: 117px;
    				height: 50px;
				}
			}
			@include media-down('sm') {
				span .app-logo,
				a .app-logo {
					width: 94px;
    				height: 40px;
				}    
			}    
		}
	}
}