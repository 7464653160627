@import '../../../scss/base.scss';


.jupiter .site-footer-j {
    width: 100%;
    border-top: 1px solid $c-text-secondary-f50;
    text-align: center;
	&.all-links {
        .d-inline-block {
            margin: auto auto;
        }
		span {
            font-size: 0.8rem;
            color: $c-text-primary;
            &.site-name {
                color: $c-accent;
            }
		}
	}
}