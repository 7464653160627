@import url("/css/ventunoFont.css");

i.vt,
span.vt {
	// font-size: 30px;
}

.v-widget {
	margin-top: 20px;
	// border: 1px solid skyblue; 
}

.v-card {
	border: 0px;
	// border: 1px solid green;
	background-color: inherit;
}

.disabledbutton {
	pointer-events: none;
	opacity: 0.4;
}

.cursor-pointer {
	cursor: pointer;
}

h2 {
	font-size: 1.6rem;
}

.widget-title {
	&.title-center {
		text-align: center;
	}
	&.title-right {
		text-align: right;
	}
	color: $c-accent;

	h2 {
		font-size: 1.2rem;
		font-family: 'Montserrat';
	}

	&.babylove h2 {
		font-family: babylove;
		letter-spacing: 0.15rem;
		font-size: 1.5rem;
	}

	h3 {
		font-size: 1.1rem;
	}

	h4 {
		font-size: 1rem;
	}

	
}

a,
a:hover {
	text-decoration: none;
	color: $c-accent;
}

a:hover {
	text-decoration: underline;
}

input {
	&::-ms-clear {
		display: none;
	}
}

.vt-select {
	@extend .vt-input;
	height: unset;
	padding: 5px 15px !important;
}

.vt-input {
	border-radius: 0;
	padding: 15px;
	font-size: .8rem;
	font-family: 'Montserrat';

	@include media-up(md) {
		padding: 15px;
		height: 52px;
		line-height: 24px;
		font-size: 1rem;

	}
}

.vt-description {
	color: $c-text-primary-f70;
}

.vt-btn-like {
	cursor: pointer;
}

.vt-btn {
	font-family: 'Montserrat';
	border-radius: 100px;
	font-weight: 600;
	border: 0;
	// box-shadow:0 0 5px 5px rgba(0,0,0,0.2);
	// transition:.3s ease;

	min-width: 80px;
	font-size: .6rem;
	margin: 0px 5px;
	padding: .6rem 1.1rem;
	letter-spacing: .03rem;
	
	&.btn-sm {
		font-size: 0.6rem;
		padding: 0.5rem 1rem;
		letter-spacing: 0rem;
		min-width: 70px;
		margin: 0px 3px;
	}

	@include media-up(md) {
		min-width: 90px;
		font-size: .7rem;
		margin: 0px 10px;
		padding: .7rem 1.2rem;
	}
	
}

.no-border-radius {
	border-radius: 0;
	color: $c-botton-text-color !important;
	background-color: $c-botton-color;

	&:hover {
		background-color: $c-botton-hover-color
	}

	&[disabled] {
		background-color: $c-botton-disabled-color
	}
}

.vt-btn-primary {
	//	 @extend .vt-btn;
	color: $c-botton-text-color !important;
	background-color: $c-botton-color;

	&:hover {
		background-color: $c-botton-hover-color
	}

	&[disabled] {
		background-color: $c-botton-disabled-color
	}
}

.vt-btn-secondary {
	color: $c-text-primary !important;
	background-color: $c-text-secondary;
	&.vt-btn-icon {
		padding-left: 2.2rem;
		svg{
			position: absolute;
			top: 0.43rem;
   			left: 1rem;
			width: 18px;
			height: 18px;
			fill: $c-text-primary;
		}
	}
	&:hover {
		background-color: $c-text-secondary-d15;
	}

	&[disabled] {
		background-color: $c-text-secondary-d30;
	}

	@include media-up(md) {
		&.vt-btn-icon {
			padding-left: 2.2rem;
			svg{
				position: absolute;
				top: 0.45rem;
				left: 1.2rem;
				width: 24px;
				height: 24px;
			}
		}
	}
}

.vt-btn-tertiary {
	background-color: transparent;
	color: $c-text-primary;
	svg{
		width: 24px;
		height: 24px;
		fill: $c-text-primary;
		margin-right:0.6rem;
	}
	&:hover {
		background-color: $c-text-primary-f10;
		color: $c-text-primary;
	}
}


.vt-icon {
	position: relative;
	width: 51px;
	height: 51px;
	min-width: 51px;
	min-height: 51px;
	padding: 0;

	div {
		position: absolute;
		width: 26px;
		height: 26px;
		top: 12px;
		left: 12px;

		svg {
			fill: $c-text-primary;
		}
	}

	@include media-down(md) {
		padding-left: 46px;

		div {
			width: 20px;
			height: 20px;
			top: 8px;
			left: 18px;
		}
	}
}

.vt-btn-link {
	color: $c-accent !important;
	background-color: transparent;
	font-size: 1rem;

	&:hover {
		color: $c-accent-l25;
		// text-decoration: underline;
	}

	@media screen and (max-width:768px) {
		font-size: .7rem;
	}
}

input[type="email"],
input[type="text"],
input[type="password"] {
	border-radius: 0;
}

.text-accent {
	color: $c-accent !important;
}

.text-primary {
	color: $c-text-primary !important;
}

.text-secondary {
	color: $c-text-secondary !important;
}

.vt-modal {
	background-color: rgba(0,0,0,0.5);
	.social-share-popup {
		max-width:100%;
		// margin-left: 1rem;
		@include media-up('sm') {
			max-width:50%;
			margin-left: auto;
		}
		@include media-up('lg') {
			max-width:30%;
		}
		.modal-content {
			min-height: 10em;
		}
		.modal-body {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}	