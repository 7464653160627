@import './components/scss/variables.scss';

@font-face {
	font-family: 'babylove';

	src: url('/css/Babylove-zGwX.ttf') format('truetype');

	font-weight: normal;
	font-style: normal
}

/*@font-face { 
	font-family: 'VAG';
	font-style: normal;
	font-weight: 900;
	src: url('/css/VAG Rounded Black_0.ttf') format('truetype');
}
@font-face {
	font-family: 'VAG';
	font-style: normal;
	font-weight: 700;
	src: url('/css/VAG Rounded Bold_0.ttf') format('truetype');
}
@font-face {
	font-family: 'VAG';
	font-style: normal;
	font-weight: 400;
	src: url('/css/VAG Rounded Regular.ttf') format('truetype');
}
@font-face {
	font-family: 'VAG';
	font-style: normal;
	font-weight: 100;
	src: url('/css/VAG Rounded Thin_0.ttf') format('truetype');
}

@font-face {
	font-family: 'VAG BT';
	font-style: normal;
	font-weight: 100 900;
	src: url('/css/VAGRounded_BT.ttf') format('truetype');
}

@font-face {
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 400;
	src: url('/css/Helvetica.ttc') format('truetype');
}*/

body {
	margin: 0;
	padding: 0 !important; //added important to override bootstrap padding.

	background-color: $c-background !important;

	background-image: $url-background-image;
	background-position-x: center;
	background-position-y: center;
	background-size: auto;
	background-repeat-x: no-repeat;
	background-repeat-y: repeat;
	background-attachment: fixed;
	background-origin: initial;
	background-clip: initial;



	font-family: 'Montserrat', "Roboto", sans-serif !important;
	// font-family: 'VAG BT', 'Helvetica', 'VAG' !important;
	// font-family:  sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@media (max-width:767px) {
	body {
		background-image: $url-background-image-sm;
	}
}