@import './_base.scss';


.site-footer {
  text-align: center;
  min-height: 100px;
  margin-top: 20px;
  border-top: 1px solid $c-text-secondary-f50;

  width: 100%;
  // margin-bottom:40px;


  .all-links {

    min-height: 100px;
    font-size: .8rem;
    a, span {
      margin: auto 0;
      color: $c-text-primary-f70;
      font-weight: 400;
      text-decoration: none;
      
        .app-logo {
          width: 206px;
          height: 67px;
        }

        .app-link:hover {
          border-bottom: 2px solid $c-botton-color;
        }
    }

    .domain-name {
      color: $c-accent;
    }


    @include media-down(sm) {
      margin: 10px auto;
    }
      


  }

}
