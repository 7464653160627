// $c-accent: #F90343;
// $c-background: black;
// $c-text-primary: white;
// $c-text-secondary: #5A5A5A;

$c-accent: var(--accent-color);
$c-background: var(--background);
$c-surface-color: var(--surface-primary);
$c-surface-secondary-color: var(--surface-secondary);

$c-text-primary: var(--text-primary);
$c-text-secondary: var(--text-secondary);
$c-header: var(--header-color);

$url-background-image: var(--background-image);
$url-background-image-sm: var(--background-image-sm);

// $c-gray-f50: fade-out(gray, .5);
// $c-red-f0: fade-out(red, 0);
// $c-black-f30: fade-out(black, .3);

$c-surface-secondary-color-f20: var(--surface-secondary-a20);
// $c-accent-f70: fade-out($c-accent, .3);
$c-accent-f70: var(--accent-color-a70);
// $c-accent-f50: fade-out($c-accent, .5);
$c-accent-f50: var(--accent-color-a50);
// $c-accent-f100: fade-out($c-accent, 1);
// $c-accent-f100: rgba(249, 3, 67, 0);
// $c-accent-d15: darken($color: $c-accent, $amount: 15%);
$c-accent-d15: var(--accent-color-d15); //#ad022f
// $c-accent-d30: darken($color: $c-accent, $amount: 30%);
$c-accent-d30: var(--accent-color-d30); //#62011a
// $c-accent-l25: lighten($color: $c-accent, $amount: 25%);
$c-accent-l25: var(--accent-color-l25); //#fd7e9f

$c-background-f80: var(--background-a80);
$c-background-f70: var(--background-a70);
$c-background-f50: var(--background-a50);
$c-background-f45: var(--background-a45);
$c-background-f20: var(--background-a20);
$c-background-f00: var(--background-a00);

$c-text-primary-f70: var(--text-primary-a70);
$c-text-primary-f50: var(--text-primary-a50);
$c-text-primary-f10: var(--text-primary-a10);
$c-text-primary-d50: var(--text-primary-d50);

$c-text-secondary-d15: var(--text-secondary-d15);
$c-text-secondary-d30: var(--text-secondary-d30);
$c-text-secondary-l25: var(--text-secondary-l25);
// $c-text-secondary-f20: rgba(90, 90, 90, 0.8);
$c-text-secondary-f50: var(--text-secondary-a50);
$c-text-secondary-f75: var(--text-secondary-a75);

$header-height: 75;

$c-botton-color: var(--botton-color);
$c-botton-color-f70: var(--botton-color-a70);
$c-botton-hover-color: var(--botton-hover-color);
$c-botton-disabled-color: var(--botton-disabled-color);
$c-botton-text-color: var(--botton-text-color);

$c-header-text-color: var(--header-text-color);

$c-vhl-header-color: var(--vhl-header-color);
//learnflixx hardcode
$c-hhl-header-color:var(--text-primary-a70);

$c-facebook-login-button-color:var(--facebook-login-button-color);
$c-facebook-login-text-color: var(--facebook-login-text-color);
$c-google-login-button-color: var(--google-login-button-color);
$c-google-login-text-color: var(--google-login-text-color);


$c-warning-color: var(--warning-color);
$c-warning-color-f30: var(--warning-color-a30);