


@mixin center-block {
    display: block;
	margin-left: auto;
	margin-right: auto;
}
@mixin vt-transition($duration) {
    -webkit-transition: all $duration ease-out;
    -moz-transition: all $duration ease-out;
    -o-transition: all $duration ease-out;
    -ms-transition: all $duration ease-out;
    transition: all $duration ease-out;
}

@mixin enddots($width) {
    max-width: $width;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@mixin slim-scroll($bg:inherit, $fg: $c-accent) {
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: $bg;
    }
    
    &::-webkit-scrollbar {
        width: 5px;
        background-color: $bg;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: $fg;
    }
}

$breakpoints: (
  xs: 390px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

@mixin media-up($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media screen and (min-width: #{map-get($breakpoints, $breakpoint)}) {
            @content;
        }
    }
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
            + "Please make sure it is defined in `$breakpoints` map.";
    }
}

@mixin media-down($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media screen and (max-width: #{map-get($breakpoints, $breakpoint)}) {
            @content;
        }
    }
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
            + "Please make sure it is defined in `$breakpoints` map.";
    }
}
