@import url(/css/ventunoFont.css);
@font-face {
  font-family: "babylove";
  src: url("/css/Babylove-zGwX.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/*@font-face { 
	font-family: 'VAG';
	font-style: normal;
	font-weight: 900;
	src: url('/css/VAG Rounded Black_0.ttf') format('truetype');
}
@font-face {
	font-family: 'VAG';
	font-style: normal;
	font-weight: 700;
	src: url('/css/VAG Rounded Bold_0.ttf') format('truetype');
}
@font-face {
	font-family: 'VAG';
	font-style: normal;
	font-weight: 400;
	src: url('/css/VAG Rounded Regular.ttf') format('truetype');
}
@font-face {
	font-family: 'VAG';
	font-style: normal;
	font-weight: 100;
	src: url('/css/VAG Rounded Thin_0.ttf') format('truetype');
}

@font-face {
	font-family: 'VAG BT';
	font-style: normal;
	font-weight: 100 900;
	src: url('/css/VAGRounded_BT.ttf') format('truetype');
}

@font-face {
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 400;
	src: url('/css/Helvetica.ttc') format('truetype');
}*/
body {
  margin: 0;
  padding: 0 !important;
  background-color: var(--background) !important;
  background-image: var(--background-image);
  background-position-x: center;
  background-position-y: center;
  background-size: auto;
  background-repeat-x: no-repeat;
  background-repeat-y: repeat;
  background-attachment: fixed;
  background-origin: initial;
  background-clip: initial;
  font-family: "Montserrat", "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 767px) {
  body {
    background-image: var(--background-image-sm);
  }
}
.v-widget {
  margin-top: 20px;
}

.v-card {
  border: 0px;
  background-color: inherit;
}

.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}

.cursor-pointer {
  cursor: pointer;
}

h2 {
  font-size: 1.6rem;
}

.widget-title {
  color: var(--accent-color);
}
.widget-title.title-center {
  text-align: center;
}
.widget-title.title-right {
  text-align: right;
}
.widget-title h2 {
  font-size: 1.2rem;
  font-family: "Montserrat";
}
.widget-title.babylove h2 {
  font-family: babylove;
  letter-spacing: 0.15rem;
  font-size: 1.5rem;
}
.widget-title h3 {
  font-size: 1.1rem;
}
.widget-title h4 {
  font-size: 1rem;
}

a,
a:hover {
  text-decoration: none;
  color: var(--accent-color);
}

a:hover {
  text-decoration: underline;
}

input::-ms-clear {
  display: none;
}

.vt-select {
  height: unset;
  padding: 5px 15px !important;
}

.vt-input, .vt-select {
  border-radius: 0;
  padding: 15px;
  font-size: 0.8rem;
  font-family: "Montserrat";
}
@media screen and (min-width: 768px) {
  .vt-input, .vt-select {
    padding: 15px;
    height: 52px;
    line-height: 24px;
    font-size: 1rem;
  }
}

.vt-description {
  color: var(--text-primary-a70);
}

.vt-btn-like {
  cursor: pointer;
}

.vt-btn {
  font-family: "Montserrat";
  border-radius: 100px;
  font-weight: 600;
  border: 0;
  min-width: 80px;
  font-size: 0.6rem;
  margin: 0px 5px;
  padding: 0.6rem 1.1rem;
  letter-spacing: 0.03rem;
}
.vt-btn.btn-sm {
  font-size: 0.6rem;
  padding: 0.5rem 1rem;
  letter-spacing: 0rem;
  min-width: 70px;
  margin: 0px 3px;
}
@media screen and (min-width: 768px) {
  .vt-btn {
    min-width: 90px;
    font-size: 0.7rem;
    margin: 0px 10px;
    padding: 0.7rem 1.2rem;
  }
}

.no-border-radius {
  border-radius: 0;
  color: var(--botton-text-color) !important;
  background-color: var(--botton-color);
}
.no-border-radius:hover {
  background-color: var(--botton-hover-color);
}
.no-border-radius[disabled] {
  background-color: var(--botton-disabled-color);
}

.vt-btn-primary {
  color: var(--botton-text-color) !important;
  background-color: var(--botton-color);
}
.vt-btn-primary:hover {
  background-color: var(--botton-hover-color);
}
.vt-btn-primary[disabled] {
  background-color: var(--botton-disabled-color);
}

.vt-btn-secondary {
  color: var(--text-primary) !important;
  background-color: var(--text-secondary);
}
.vt-btn-secondary.vt-btn-icon {
  padding-left: 2.2rem;
}
.vt-btn-secondary.vt-btn-icon svg {
  position: absolute;
  top: 0.43rem;
  left: 1rem;
  width: 18px;
  height: 18px;
  fill: var(--text-primary);
}
.vt-btn-secondary:hover {
  background-color: var(--text-secondary-d15);
}
.vt-btn-secondary[disabled] {
  background-color: var(--text-secondary-d30);
}
@media screen and (min-width: 768px) {
  .vt-btn-secondary.vt-btn-icon {
    padding-left: 2.2rem;
  }
  .vt-btn-secondary.vt-btn-icon svg {
    position: absolute;
    top: 0.45rem;
    left: 1.2rem;
    width: 24px;
    height: 24px;
  }
}

.vt-btn-tertiary {
  background-color: transparent;
  color: var(--text-primary);
}
.vt-btn-tertiary svg {
  width: 24px;
  height: 24px;
  fill: var(--text-primary);
  margin-right: 0.6rem;
}
.vt-btn-tertiary:hover {
  background-color: var(--text-primary-a10);
  color: var(--text-primary);
}

.vt-icon {
  position: relative;
  width: 51px;
  height: 51px;
  min-width: 51px;
  min-height: 51px;
  padding: 0;
}
.vt-icon div {
  position: absolute;
  width: 26px;
  height: 26px;
  top: 12px;
  left: 12px;
}
.vt-icon div svg {
  fill: var(--text-primary);
}
@media screen and (max-width: 768px) {
  .vt-icon {
    padding-left: 46px;
  }
  .vt-icon div {
    width: 20px;
    height: 20px;
    top: 8px;
    left: 18px;
  }
}

.vt-btn-link {
  color: var(--accent-color) !important;
  background-color: transparent;
  font-size: 1rem;
}
.vt-btn-link:hover {
  color: var(--accent-color-l25);
}
@media screen and (max-width: 768px) {
  .vt-btn-link {
    font-size: 0.7rem;
  }
}

input[type=email],
input[type=text],
input[type=password] {
  border-radius: 0;
}

.text-accent {
  color: var(--accent-color) !important;
}

.text-primary {
  color: var(--text-primary) !important;
}

.text-secondary {
  color: var(--text-secondary) !important;
}

.vt-modal {
  background-color: rgba(0, 0, 0, 0.5);
}
.vt-modal .social-share-popup {
  max-width: 100%;
}
@media screen and (min-width: 576px) {
  .vt-modal .social-share-popup {
    max-width: 50%;
    margin-left: auto;
  }
}
@media screen and (min-width: 992px) {
  .vt-modal .social-share-popup {
    max-width: 30%;
  }
}
.vt-modal .social-share-popup .modal-content {
  min-height: 10em;
}
.vt-modal .social-share-popup .modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.v-card {
  background-color: inherit;
}

.carousel-control-prev span, .carousel-control-next span {
  display: inline-block;
  font: normal normal normal 14px/1 ventuno;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  font-size: 55px;
  line-height: 0;
  color: var(--botton-color);
  background: none;
  width: inherit;
  height: inherit;
}
.carousel-control-prev span::before, .carousel-control-next span::before {
  content: "M";
}

.carousel-control-next span::before {
  content: "M";
}

.carousel-control-prev span {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.carousel-control-prev span::before {
  content: "M";
}
.vtn-cover-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.5s linear;
}
.vtn-cover-screen.vt-vis {
  transition: opacity 0.5s linear 0.3s;
  opacity: 1;
}
.limited-viewing-popup {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 998;
  display: flex;
  align-items: center;
  justify-content: center;
}
.limited-viewing-popup .message-box {
  width: 90%;
  max-width: 500px;
  height: 90%;
  max-height: 281.25px;
  background-color: var(--background-a50);
  border: 1px solid var(--text-secondary-a50);
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.limited-viewing-popup .message-box img {
  width: 100%;
}
.limited-viewing-popup .message-box .text-wrp {
  width: 100%;
}
.limited-viewing-popup .message-box h2 {
  margin-top: 20px;
  color: var(--text-primary);
  margin-bottom: 40px;
  font-size: 1.2rem;
  padding: 0 5%;
}
.limited-viewing-popup .message-box .btns {
  height: 40px;
  margin-bottom: 15px;
  width: 100%;
}
.limited-viewing-popup .message-box .btns .btn {
  margin-left: 10px;
}
@media screen and (max-width: 992px) {
  .limited-viewing-popup .message-box h2 {
    font-size: 1.1rem;
    margin-bottom: 35px;
  }
  .limited-viewing-popup .message-box .btns {
    height: 37px;
  }
  .limited-viewing-popup .message-box .btns .btn {
    margin-left: 9px;
  }
}
@media screen and (max-width: 768px) {
  .limited-viewing-popup .message-box h2 {
    font-size: 0.95rem;
    margin-bottom: 25px;
  }
  .limited-viewing-popup .message-box .btns {
    height: 34px;
  }
  .limited-viewing-popup .message-box .btns .btn {
    margin-left: 8px;
  }
}
@media screen and (max-width: 576px) {
  .limited-viewing-popup .message-box h2 {
    font-size: 0.75rem;
    margin-bottom: 20px;
  }
  .limited-viewing-popup .message-box .btns {
    height: 31px;
  }
  .limited-viewing-popup .message-box .btns .btn {
    margin-left: 7px;
  }
}
.site-body {
  min-height: calc(100vh - 340px);
}
.shopping-cart {
  top: 0;
  left: 0;
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s linear 0.5s, opacity 0.3s linear 0.5s;
}
.shopping-cart.open {
  transition: visibility 0.2s linear, opacity 0.3s linear 0.5s;
  visibility: visible;
  opacity: 1;
}
.shopping-cart.open .cart-area {
  transition: right 0.5s ease-in 0.3s;
  right: 0;
}
.shopping-cart .cart-area {
  position: absolute;
  top: 0;
  right: -505px;
  width: 500px;
  height: 100%;
  padding-top: 75px;
  transition: right 0.5s ease-out;
}
.shopping-cart .ca-wrpr {
  padding: 15px 10px 10px;
  width: 100%;
  height: 100%;
  border-left: 1px solid var(--text-secondary-a50);
  background: var(--header-color);
  overflow-y: auto;
}
.shopping-cart .container {
  position: relative;
}
.shopping-cart .close-icon {
  position: absolute;
  top: 5px;
  left: 10px;
  width: 18px;
  height: 18px;
  line-height: 1;
  color: var(--text-primary);
  font-size: 1.3rem;
}
.shopping-cart .close-icon:hover {
  color: var(--accent-color);
}
.empty-cart-card .empty-cart-msg {
  text-align: center;
  color: var(--text-primary);
  font-weight: 600;
}
.empty-cart-card .empty-cart-grf {
  width: 300px;
  height: 300px;
  padding: 15px;
  margin: 30px auto;
}
.empty-cart-card .cart-links {
  margin-top: 15px;
}
.empty-cart-card .cart-links .cart-links-msg {
  text-align: center;
  color: var(--text-secondary);
  font-size: 12px;
}
.empty-cart-card .cart-links a, .empty-cart-card .cart-links a:hover {
  color: var(--text-secondary);
}
svg.EmptyCart {
  fill: var(--text-secondary);
  opacity: 0.5;
}
.shopping-cart-card {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}
.shopping-cart-card .cart-item-image img {
  width: 100%;
}
.shopping-cart-card .cart-item-name {
  color: var(--text-primary);
}
.shopping-cart-card .cart-item-total {
  color: var(--text-secondary);
  font-weight: 600;
  font-size: 19px;
}
.shopping-cart-card .cart-item-remove {
  position: absolute;
  top: 3px;
  right: 0;
  width: 18px;
  height: 18px;
  line-height: 1;
  color: var(--text-primary);
  font-size: 1rem;
}
.shopping-cart-card .cart-item-remove:hover {
  color: var(--accent-color);
}
.product-quantity {
  margin-top: 3px;
  font-size: 0.9rem;
  color: var(--text-primary);
}
.product-quantity .pr20 {
  margin-right: 20px;
}
.product-quantity .qty-num {
  margin: 0 8px;
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-secondary);
}
.product-quantity .btn-qty {
  font-size: 1.4rem;
  line-height: 1;
  position: relative;
  bottom: 2px;
  padding: 0;
  color: var(--text-secondary);
  /*
  color: $c-text-primary;
  width: 24px;
  height: 24px;
  background: $c-text-secondary;
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  border-radius: 50%;
  padding-top: 1px;
  padding-left: 5px;
  margin:0 5px;
  line-height: 1;
  &.pq-min {
  	padding-top: 0px;
  	padding-left: 7px;
  }
  */
}
.product-quantity .btn-qty:hover {
  color: var(--text-primary);
}
.product-quantity .btn-qty.vt-disabld {
  color: var(--text-secondary-a50);
  cursor: default;
}
.static-quantity {
  margin-top: 3px;
  font-size: 0.9rem;
  color: rgba(var(--text-primary), 0.7);
}
.static-quantity .pr20 {
  margin-right: 7px;
}
.static-quantity .qty-num {
  margin: 0 8px;
  color: var(--text-primary);
}
.cart-summary-card {
  color: var(--text-primary);
  font-size: 12px;
  margin-bottom: 40px;
}
.cart-summary-card .summary-top-pad {
  padding-top: 10px;
}
.cart-summary-card .row {
  padding-bottom: 4px;
}
.cart-summary-card .row.summary-wrpr {
  padding-bottom: 15px;
}
.cart-summary-card .summary-border {
  padding-bottom: 6px;
  margin-bottom: 8px;
  border-bottom: 1px solid var(--text-secondary);
}
.cart-summary-card .summary-title h3 {
  font-weight: 600;
  font-size: 16px;
}
.cart-summary-card .summary-right {
  text-align: right;
}
.cart-summary-card .summary-total {
  font-weight: 600;
}
.cart-summary-card .sh-cart-btn {
  height: 51px;
  min-height: 51px;
}
.cart-summary-card .sh-cart-btn .vt-btn {
  position: absolute;
  right: 0;
}
.shopping-cart-content {
  width: 100%;
}
.shopping-cart-content .widget-title {
  margin-bottom: 30px;
}
.shopping-cart-content h2 {
  text-align: center;
}
.site-footer {
  text-align: center;
  min-height: 100px;
  margin-top: 20px;
  border-top: 1px solid var(--text-secondary-a50);
  width: 100%;
}
.site-footer .all-links {
  min-height: 100px;
  font-size: 0.8rem;
}
.site-footer .all-links a, .site-footer .all-links span {
  margin: auto 0;
  color: var(--text-primary-a70);
  font-weight: 400;
  text-decoration: none;
}
.site-footer .all-links a .app-logo, .site-footer .all-links span .app-logo {
  width: 206px;
  height: 67px;
}
.site-footer .all-links a .app-link:hover, .site-footer .all-links span .app-link:hover {
  border-bottom: 2px solid var(--botton-color);
}
.site-footer .all-links .domain-name {
  color: var(--accent-color);
}
@media screen and (max-width: 576px) {
  .site-footer .all-links {
    margin: 10px auto;
  }
}
.privacy_check {
  position: fixed;
  bottom: 0px;
  min-height: 70px;
  height: auto;
  width: 100%;
  background-color: var(--header-color);
  z-index: 6000;
  color: var(--header-text-color);
}
.privacy_check .row {
  margin: 20px 5px;
}
.privacy_check .label-hldr {
  margin-top: 13px;
}

.earth .privacy_check .label-hldr {
  margin-top: 4px;
}
body.earth {
  font-family: "Open Sans", sans-serif !important;
  /* show and card css overridews */
  /* video grid listing overrides */
  /* Let's get this party started */
  /* Track */
  /* Handle */
  /* EPG */
}
body.earth .vt-input,
body.earth .plans-listing .plan-box,
body.earth .vt-select {
  font-family: "Open Sans", sans-serif;
}
body.earth .vt-btn {
  font-family: "Open Sans", sans-serif;
  border-radius: 100px;
  font-weight: 600;
  border: 0;
  min-width: 100px;
  font-size: 0.7rem;
  margin: 0 0.6rem 0 0;
  padding: 0.4rem 1.4rem;
  letter-spacing: 0rem;
}
@media screen and (max-width: 768px) {
  body.earth .vt-btn {
    min-width: 100px;
    font-size: 0.6rem;
    margin: 0;
    padding: 0.4rem 1.2rem;
    letter-spacing: 0rem;
  }
}
body.earth .vt-btn.btn-sm {
  font-size: 0.6rem;
  margin: 0;
  padding: 0.5rem 0.4rem;
  letter-spacing: 0rem;
}
body.earth .vt-btn.vt-play {
  position: relative;
  padding-left: 2.3rem;
}
body.earth .vt-btn.vt-play::before {
  position: absolute;
  font-size: 0.8rem;
  font-family: "Font Awesome 5 Free";
  top: 0.3rem;
  left: 1.2rem;
  content: "\F04B";
}
@media screen and (max-width: 768px) {
  body.earth .vt-btn.vt-play {
    padding-left: 2rem;
  }
  body.earth .vt-btn.vt-play::before {
    font-size: 0.7rem;
    top: 0.35rem;
    left: 0.9rem;
  }
}
body.earth .payment .card .cancel-button > .vt-btn {
  color: var(--accent-color) !important;
  font-size: 0.9rem;
  border: none !important;
  background: none !important;
}
body.earth .plans-listing .info_text .text-secondary {
  color: var(--accent-color) !important;
  font-size: 1rem;
  margin-right: 0.5rem;
  margin-top: 1rem;
}
@media screen and (max-width: 768px) {
  body.earth .plans-listing .info_text .text-secondary {
    font-size: 0.85rem;
    margin-top: 0.6rem;
    margin-right: 0;
    text-align: center !important;
  }
}
body.earth .vt-btn-link {
  color: var(--text-primary) !important;
  border: 1px solid var(--text-primary-a50) !important;
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 0.7rem;
}
body.earth .vt-btn-link:hover {
  color: var(--accent-color) !important;
}
@media screen and (max-width: 768px) {
  body.earth .vt-btn-link {
    font-size: 0.6rem;
  }
}
body.earth .widget-title h2 {
  font-size: 1.1rem;
  font-weight: 600;
  font-family: "Open Sans", sans-serif !important;
  color: var(--text-primary) !important;
  margin-bottom: 14px;
  margin-left: 10px;
}
@media screen and (min-width: 576px) {
  body.earth .container {
    max-width: 600px;
  }
}
@media screen and (min-width: 768px) {
  body.earth .container {
    max-width: 900px;
  }
}
@media screen and (min-width: 992px) {
  body.earth .container {
    max-width: 1075px;
  }
}
@media screen and (min-width: 1200px) {
  body.earth .container {
    max-width: 1250px;
  }
}
@media screen and (min-width: 768px) {
  body.earth .video-carousel .carousel-sm {
    display: block;
  }
}
body.earth .video-carousel .carousel-sm .carousel-control-next,
body.earth .video-carousel .carousel-sm .carousel-control-prev {
  width: 8%;
}
@media screen and (max-width: 768px) {
  body.earth .video-carousel .carousel-sm .carousel-control-next,
body.earth .video-carousel .carousel-sm .carousel-control-prev {
    width: 13%;
  }
}
body.earth .show-card .primary-thumb {
  border-radius: 0.75rem;
}
body.earth .hybrid-list-card-l2 {
  margin: 0% 0.5%;
}
body.earth .hybrid-list-card-l2 .primary-thumb {
  border-radius: 0.75rem;
}
body.earth .video-grid-listing .widget-title {
  display: none;
}
body.earth .video-grid-listing .outer-wrap {
  padding-left: 23px;
  padding-right: 23px;
}
body.earth ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
body.earth ::-webkit-scrollbar-thumb {
  background: var(--text-primary-a50);
}
body.earth ::-webkit-scrollbar-thumb:window-inactive {
  background: var(--text-primary-a10);
}
@media screen and (min-width: 992px) {
  body.earth .channel-epg-horizontal-listing .inner-wrpr {
    width: 70%;
    max-width: 1100px;
  }
}
body.earth .channel-epg-horizontal-listing .inner-wrpr .img-hldr {
  border-radius: 0.75rem;
  overflow: hidden;
}
body.earth .no-epg-card,
body.earth .channel-epg-card {
  background-color: var(--text-primary-a10);
}
body.earth .no-epg-card .txt-wrpr .time-txt::before,
body.earth .channel-epg-card .txt-wrpr .time-txt::before {
  top: 0.35rem;
}
body.earth .new-episode {
  top: 1px;
  right: 1px;
  border-top-right-radius: 0.65rem;
  border-bottom-left-radius: 0.65rem;
  padding: 0 8px;
  padding-top: 3px;
  min-width: 29px;
  height: 22px;
}
@media screen and (max-width: 992px) and (min-width: 768px) {
  body.earth .new-episode {
    border-top-right-radius: 0.45rem;
    border-bottom-left-radius: 0.45rem;
    font-size: 0.5rem;
    height: 16px;
    padding-top: 2px;
  }
}
body.earth .hybrid-horizontal-listing .carousel-control-next,
body.earth .hybrid-horizontal-listing .carousel-control-prev {
  z-index: 2;
}
body.earth .hybrid-horizontal-listing .carousel-control-next span,
body.earth .hybrid-horizontal-listing .carousel-control-prev span {
  height: 66px;
}
body.earth .hybrid-horizontal-listing .content-wrpr .title {
  margin-top: 0.3rem;
  font-size: 0.9rem;
}
body.earth .live-video-details-card {
  margin: 0 4.37rem;
}
body.jupiter {
  font-family: "Poppins", sans-serif !important;
}
body.jupiter .widget-title h2 {
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif !important;
  color: var(--accent-color);
}
body.jupiter .vt-btn,
body.jupiter .vt-input {
  font-family: "Poppins", sans-serif !important;
}
body.jupiter .video-horizontal-listing .widget-title,
body.jupiter .show-horizontal-listing .widget-title,
body.jupiter .movie-horizontal-listing .widget-title,
body.jupiter .hybrid-horizontal-listing .widget-title {
  position: relative;
}
body.jupiter .video-horizontal-listing .widget-title::before,
body.jupiter .show-horizontal-listing .widget-title::before,
body.jupiter .movie-horizontal-listing .widget-title::before,
body.jupiter .hybrid-horizontal-listing .widget-title::before {
  display: block;
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), var(--text-secondary-a50), rgba(0, 0, 0, 0));
  top: 55%;
  left: 0;
}
body.jupiter .video-horizontal-listing .widget-title h2,
body.jupiter .show-horizontal-listing .widget-title h2,
body.jupiter .movie-horizontal-listing .widget-title h2,
body.jupiter .hybrid-horizontal-listing .widget-title h2 {
  position: relative;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  font-family: "Poppins", sans-serif !important;
  padding-top: 5px;
  margin: 0 auto 12px;
  text-align: center;
  text-transform: uppercase;
  width: -webkit-fit-content;
  width: fit-content;
  padding-left: 7px;
  padding-right: 7px;
  max-width: 80%;
  background-color: var(--background);
}
body.jupiter .vt-btn {
  font-family: "Poppins", sans-serif;
  border-radius: 8px;
}
body.jupiter .search-form form div.input-group .search-box {
  border-radius: 8px;
  border: none;
  background-color: var(--text-secondary-a50);
  padding-right: 31px;
}
body.jupiter .search-form form div.input-group .search-button {
  background-color: rgba(0, 0, 0, 0);
  width: 33px;
}
@media screen and (min-width: 992px) {
  body.jupiter .movie-details-card {
    margin-bottom: 70px;
    min-height: 360px;
  }
}
body.jupiter .plans-listing .plan-box {
  font-family: "Poppins", sans-serif;
}
body.jupiter .site-header .app-logo {
  width: 130px;
  height: 33px;
}
body.jupiter .site-header .nav-item .nav-link.true-link:hover:after,
body.jupiter .site-header .nav-item .nav-link.true-link.active:after {
  display: none;
}
body.jupiter .site-header .nav-item .nav-link.true-link:hover,
body.jupiter .site-header .nav-item .nav-link.true-link.active {
  color: var(--accent-color);
}
@media screen and (min-width: 992px) {
  body.jupiter .site-header .vt-btn {
    min-width: 55px;
    font-size: 0.7rem;
    margin: 0 6px;
    padding: 0.7rem 1.2rem;
  }
  body.jupiter .site-header .navbar-brand {
    margin-right: 2.4rem;
  }
  body.jupiter .site-header .app-logo {
    width: 200px;
    height: 51px;
  }
  body.jupiter .site-header .search-form .form-inline {
    position: relative;
    width: 260px;
  }
  body.jupiter .site-header .search-form form div.input-group {
    width: 80%;
    margin: 0 6px;
    position: absolute;
    right: 0;
  }
  body.jupiter .site-header .search-form form div.input-group:focus-within {
    width: 100%;
  }
  body.jupiter .site-header .all-links {
    min-width: auto;
    height: 41px;
    background-color: var(--text-secondary-a50);
    border-radius: 8px;
  }
  body.jupiter .site-header .all-links .nav-item .nav-link {
    margin: 3px 3px;
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  body.jupiter .site-header .dropdown-menu {
    background-color: var(--text-secondary-d30);
    border-radius: 8px;
  }
  body.jupiter .site-header .dropdown-menu a, body.jupiter .site-header .dropdown-menu span {
    font-weight: 600;
  }
  body.jupiter .site-header .dropdown-menu.sub-link {
    top: 146%;
  }
  body.jupiter .site-header .user-profile-button .dropdown-menu {
    left: -5.5em;
  }
}
@media screen and (min-width: 1200px) {
  body.jupiter .site-header ul.main-nav {
    display: block;
    margin: auto;
  }
  body.jupiter .site-header .all-links .nav-item .nav-link {
    margin: 3px 10px;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}
body.jupiter .video-carousel .carousel-control-prev span::before,
body.jupiter .video-carousel .carousel-control-next span::before {
  padding-top: 16px;
  padding-left: 3px;
  display: block;
  width: 34px;
  height: 34px;
  background-color: var(--botton-color);
  border-radius: 8px;
  color: var(--botton-text-color);
  font-size: 1.8rem;
}
body.jupiter .carousel-indicators li {
  width: 5px;
  height: 5px;
  border-radius: 2px;
}
body.jupiter .cnhl.carousel-control-prev span,
body.jupiter .cnhl.carousel-control-next span,
body.jupiter .chl.carousel-control-prev span,
body.jupiter .chl.carousel-control-next span,
body.jupiter .mhl.carousel-control-prev span,
body.jupiter .mhl.carousel-control-next span,
body.jupiter .shl.carousel-control-prev span,
body.jupiter .shl.carousel-control-next span,
body.jupiter .vhl.carousel-control-prev span,
body.jupiter .vhl.carousel-control-next span {
  font-size: 28px;
}
body.jupiter .video-details .video-actions {
  background-color: var(--text-secondary-d30);
  padding: 10px;
  border-radius: 8px;
  width: -webkit-fit-content;
  width: fit-content;
}
body.jupiter .watch-list-cntrls .add-icon,
body.jupiter .add-watch-over .add-icon {
  background-color: var(--botton-color);
}
body.jupiter .watch-list-cntrls .add-icon svg,
body.jupiter .add-watch-over .add-icon svg {
  fill: var(--botton-text-color);
}
body.jupiter .movie-details-card .video-genre,
body.jupiter .movie-details-card .video-cast-crew,
body.jupiter .movie-details-card .video-cast-crew .sec-text {
  font-family: "Poppins", sans-serif !important;
}
@media only screen and (min-width: 1100px) {
  body.venus .navbar .search-form {
    width: 300px;
  }
}
body.venus .hybrid-horizontal-listing .widget-title {
  color: var(--accent-color);
}
body.venus .hybrid-list-card-l3 .card-content .content-wrpr .title {
  font-size: 1.05rem;
  font-weight: 600;
}
body.venus .hybrid-grid-listing .widget-title {
  margin-bottom: 30px;
  color: var(--text-secondary);
}
body.venus .hybrid-details-card-l2 .left-col .description {
  color: var(--text-secondary);
  margin: 1rem 0;
  font-size: 0.85rem;
  line-height: 1.5;
}
body.venus .fb-google-login-button, body.venus .facebook-login-button {
  border-radius: 100px;
}
body.mars {
  font-family: "Poppins", sans-serif !important;
}
body.mars .vt-input,
body.mars .plans-listing .plan-box,
body.mars .vt-select {
  font-family: "Poppins", sans-serif;
}
body.mars .vt-btn {
  font-family: "Poppins", sans-serif;
  border-radius: 4px;
  font-weight: 600;
  font-size: 0.7rem;
  margin: 0 0.6rem 0 0;
}
@media screen and (max-width: 768px) {
  body.mars .vt-btn {
    min-width: 100px;
    font-size: 0.6rem;
  }
}
body.mars .vt-btn.btn-sm {
  font-size: 0.6rem;
  margin: 0;
  padding: 0.6rem 0.4rem;
  letter-spacing: 0rem;
}
body.mars .vt-btn.vt-play {
  position: relative;
  padding-left: 2.3rem;
}
body.mars .vt-btn.vt-play::before {
  position: absolute;
  font-size: 0.8rem;
  font-family: "Font Awesome 5 Free";
  top: 0.3rem;
  left: 1.2rem;
  content: "\F04B";
}
@media screen and (max-width: 768px) {
  body.mars .vt-btn.vt-play {
    padding-left: 2rem;
  }
  body.mars .vt-btn.vt-play::before {
    font-size: 0.7rem;
    top: 0.35rem;
    left: 0.9rem;
  }
}
body.mars .widget-title h2 {
  font-size: 1.1rem;
  font-weight: 400;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 14px;
  margin-left: 0.2rem;
}
body.mars .hybrid-list-card-l2 .content-wrpr .title,
body.mars .hybrid-list-card-l4 .content-wrpr .title {
  font-size: 0.9rem;
}
body.mars .navbar .user-profile-button {
  margin-right: 0.4rem;
}
body.mars .navbar .search-form {
  margin-right: 1rem;
  min-width: 25rem;
  border-radius: 4px;
}
@media screen and (max-width: 1200px) {
  body.mars .navbar .search-form {
    min-width: 16rem;
  }
}
body.mars .navbar .search-box {
  border-radius: 4px !important;
}
body.mars .navbar .search-btn {
  border-radius: 4px;
}
body.mars .site-footer {
  margin-top: 3rem;
}
body.mars .register-form .terms-text {
  color: var(--text-secondary);
}
.earth .site-footer {
  background-color: var(--header-color);
  border: none;
  text-align: left;
  margin-top: 30px;
}
.earth .site-footer .widget-title h2 {
  margin-left: 0;
}
.earth .site-footer .bottom-line {
  border-bottom: 2px solid var(--text-secondary-a50);
}
.earth .site-footer .all-links {
  min-height: 70px;
}
.earth .site-footer .all-links a {
  margin-right: 24px;
}
.earth .site-footer .container {
  padding: 30px 30px 10px;
}
.earth .site-footer .all-links .nav-item {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
@media screen and (max-width: 992px) {
  .earth .site-footer .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.earth .site-footer .icon-link::before {
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 800;
  font-size: 1rem;
  content: "\F007";
  margin-right: 8px;
  position: relative;
  top: 2px;
}
@media screen and (max-width: 768px) {
  .earth .site-footer .all-links,
.earth .site-footer .all-links a {
    font-size: 0.75rem;
  }
  .earth .site-footer .all-links {
    min-height: 50px;
  }
  .earth .site-footer .widget-title h2 {
    font-size: 1rem;
    margin-bottom: 10px;
    margin-left: 7px;
  }
}
@media screen and (max-width: 576px) {
  .earth .site-footer .widget-title h2 {
    font-size: 0.9rem;
    margin-bottom: 7px;
    margin-left: 5px;
  }
  .earth .site-footer .all-links a {
    font-size: 0.65rem;
  }
}
@media screen and (max-width: 576px) {
  .earth .site-footer-e.all-links {
    margin: 10px 0 0;
  }
}
.earth .site-footer-e.all-links span {
  color: var(--text-secondary);
}
.earth .site-footer-e.all-links span span .app-logo,
.earth .site-footer-e.all-links span a .app-logo {
  width: 120px;
  height: 51px;
}
@media screen and (max-width: 768px) {
  .earth .site-footer-e.all-links span span .app-logo,
.earth .site-footer-e.all-links span a .app-logo {
    width: 117px;
    height: 50px;
  }
}
@media screen and (max-width: 576px) {
  .earth .site-footer-e.all-links span span .app-logo,
.earth .site-footer-e.all-links span a .app-logo {
    width: 94px;
    height: 40px;
  }
}
.earth .social-links {
  min-height: 100px;
}
.earth .social-links .all-links a.social-icon {
  margin-right: 34px;
}
.earth .social-links a.social-icon.facebook::before {
  font-family: "Font Awesome 5 Brands";
  content: "\F39E";
}
.earth .social-links a.social-icon.twitter::before {
  font-family: "Font Awesome 5 Brands";
  content: "\F099";
}
.earth .social-links a.social-icon.instagram::before {
  font-family: "Font Awesome 5 Brands";
  content: "\F16D";
}
@media screen and (max-width: 576px) {
  .earth .social-links .all-links a.social-icon {
    margin-right: 15px;
  }
}
.earth .footer-links .all-links {
  margin-top: 22px;
  margin-bottom: 22px;
  padding: 0;
  float: left;
}
.earth .footer-links .all-links.two-clm {
  width: 50%;
}
.earth .footer-links .all-links .nav-item {
  margin-bottom: 15px;
  list-style: none;
  font-size: 0.9rem;
}
.earth .footer-links .all-links .nav-item a {
  cursor: pointer;
  color: var(--text-secondary);
}
.earth .footer-links .all-links .nav-item a:hover {
  color: var(--text-primary);
}
.earth .footer-links .all-links .nav-item a.icon-link.play::before {
  font-family: "Font Awesome 5 Free";
  content: "\F04B";
  top: 0;
}
.earth .footer-links .all-links .nav-item.big {
  padding-top: 25px;
}
.earth .footer-links .all-links .nav-item.big a {
  font-weight: 600;
  font-size: 1.1rem;
  color: var(--text-primary);
}
@media screen and (max-width: 768px) {
  .earth .footer-links .all-links {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .earth .footer-links .all-links .nav-item {
    margin-bottom: 9px;
  }
}
@media screen and (max-width: 576px) {
  .earth .footer-links .all-links {
    margin-top: 11px;
    margin-bottom: 11px;
  }
  .earth .footer-links .all-links .nav-item {
    margin-bottom: 5px;
  }
  .earth .footer-links .all-links .nav-item.big {
    padding-top: 20px;
  }
  .earth .footer-links .all-links .nav-item.big a {
    font-size: 0.9rem;
  }
}
.jupiter .site-footer {
  margin-top: 40px;
  background-color: var(--text-secondary-d30);
}
.jupiter .site-footer .icon-link {
  color: var(--accent-color);
}
.jupiter .site-footer .icon-link::before {
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 800;
  font-size: 1.2rem;
  content: "\F007";
  margin-right: 8px;
  position: relative;
  top: 2px;
}
.jupiter .social-links {
  min-height: 100px;
  text-align: left;
}
.jupiter .social-links .all-links {
  padding-top: 15px;
}
.jupiter .social-links .all-links a.social-icon {
  margin-right: 34px;
}
.jupiter .social-links .app-logo img {
  width: 200px;
  height: 51px;
}
.jupiter .social-links a.social-icon {
  margin-right: 10px;
}
.jupiter .social-links a.social-icon::before {
  font-size: 2.4rem;
}
.jupiter .social-links a.social-icon.facebook::before {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
  content: "\F082";
}
.jupiter .social-links a.social-icon.twitter::before {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
  content: "\F081";
}
.jupiter .social-links a.social-icon.instagram::before {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
  content: "\F16D";
}
.jupiter .social-links a.social-icon.youtube::before {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
  content: "\F167";
}
@media screen and (max-width: 992px) {
  .jupiter .social-links {
    min-height: 80px;
  }
  .jupiter .social-links .all-links {
    min-height: 80px;
    padding-top: 13px;
  }
  .jupiter .social-links .all-links a.social-icon {
    margin-right: 20px;
  }
  .jupiter .social-links .all-links a.social-icon::before {
    font-size: 2.1rem;
  }
  .jupiter .social-links .app-logo img {
    width: 180px;
    height: 46px;
  }
}
@media screen and (max-width: 768px) {
  .jupiter .social-links {
    min-height: 60px;
  }
  .jupiter .social-links .all-links {
    min-height: 60px;
    padding-top: 10px;
  }
  .jupiter .social-links .all-links a.social-icon {
    float: left;
    margin-right: 15px;
  }
  .jupiter .social-links .all-links a.social-icon::before {
    font-size: 1.8rem;
  }
  .jupiter .social-links .app-logo img {
    width: 160px;
    height: 41px;
  }
}
@media screen and (max-width: 576px) {
  .jupiter .social-links .all-links a.social-icon {
    margin-right: 0;
    margin-right: 6px;
  }
}
.jupiter .site-footer-j {
  width: 100%;
  border-top: 1px solid var(--text-secondary-a50);
  text-align: center;
}
.jupiter .site-footer-j.all-links .d-inline-block {
  margin: auto auto;
}
.jupiter .site-footer-j.all-links span {
  font-size: 0.8rem;
  color: var(--text-primary);
}
.jupiter .site-footer-j.all-links span.site-name {
  color: var(--accent-color);
}
.jupiter .footer-links {
  color: var(--text-primary);
  text-align: left;
}
.jupiter .footer-links .all-links {
  margin-top: 22px;
  margin-bottom: 22px;
  padding: 0;
}
.jupiter .footer-links .all-links.two-clm {
  width: 50%;
}
.jupiter .footer-links .all-links .nav-item {
  margin-bottom: 15px;
  list-style: none;
  font-size: 0.9rem;
}
.jupiter .footer-links .all-links .nav-item a {
  color: var(--text-primary);
}
.jupiter .footer-links .all-links .nav-item a:hover {
  color: var(--accent-color);
}
.jupiter .footer-links .all-links .nav-item a.big {
  font-weight: 600;
}
.jupiter .footer-links .all-links .nav-item a.icon-link.play::before {
  font-family: "Font Awesome 5 Free";
  content: "\F04B";
  top: 0;
}
@media screen and (max-width: 768px) {
  .jupiter .footer-links .all-links {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .jupiter .footer-links .all-links .nav-item {
    margin-bottom: 9px;
  }
}
@media screen and (max-width: 576px) {
  .jupiter .footer-links .all-links {
    margin-top: 11px;
    margin-bottom: 11px;
  }
  .jupiter .footer-links .all-links .nav-item {
    margin-bottom: 5px;
  }
}
.search-form form div.input-group {
  width: 90%;
  margin: auto;
  transition: width 0.4s linear;
}
.search-form form div.input-group .search-button, .search-form form div.input-group .search-box {
  background-color: var(--background);
  border-radius: 50px;
  color: var(--text-primary);
}
.search-form form div.input-group .search-button:focus, .search-form form div.input-group .search-box:focus {
  box-shadow: none;
}
.search-form form div.input-group.in-page {
  width: 50%;
}
@media screen and (max-width: 992px) {
  .search-form form div.input-group.in-page {
    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  .search-form form div.input-group.in-page {
    width: 85%;
  }
}
@media screen and (max-width: 992px) {
  .search-form form div.input-group {
    width: 70%;
  }
}
.search-form form div.input-group .search-box {
  border: 1px solid var(--text-secondary);
  border-radius: 30px;
  height: 36px;
  font-size: 0.85rem;
  padding-right: 36px;
}
@media screen and (min-width: 768px) {
  .search-form form div.input-group .search-box {
    margin-top: 5px;
    height: 41px;
  }
}
.search-form form div.input-group .search-button {
  height: 34px;
  position: absolute;
  z-index: 3;
  top: 1px;
  right: 5px;
  padding: 0 10px 0 2px;
  margin: 0;
}
@media screen and (min-width: 768px) {
  .search-form form div.input-group .search-button {
    height: 39px;
    top: 6px;
  }
}
.search-form form div.input-group .search-button .vt.vt-search {
  font-size: 33px;
}
@media screen and (min-width: 768px) {
  .search-form form div.input-group .search-button .vt.vt-search {
    font-size: 38px;
  }
}
.search-form form div.input-group .search-list {
  width: 100%;
}
.search-form .suggestions {
  text-align: center;
  list-style-type: none;
  color: var(--text-primary);
}
.search-form .suggestions .widget-title {
  color: var(--accent-color);
}
.search-form .suggestions .widget-title h2 {
  font-size: 1.5rem;
  font-family: "Montserrat";
}

.search-form-V2 .container {
  max-width: 40%;
  margin: 0 auto;
  margin-top: 8rem;
}
.search-form-V2 .search-bar {
  min-width: 76%;
  height: 36px;
  border: none;
  background-color: #343434;
  text-align: justify;
  padding-left: 15px;
  color: white;
  outline: none;
}
.search-form-V2 .search-btn {
  width: 26%;
  height: 38px;
  margin-left: -29px;
  border: none;
  background-color: #bb3d8b;
  color: white;
  font-weight: bold;
  outline: none;
}
.search-form-V2 .search-btn:hover {
  background-color: #a72e79;
}
.search-form-V2 .search-bar:hover {
  background-color: #3b3a3a;
}
.search-form-V2 .search-card {
  color: white;
  text-underline-offset: 1px solid white;
  font-weight: 10;
  padding-top: 10px;
  padding-left: 20px;
  border-bottom: 1px solid white;
  max-width: 90;
}
.search-form-V2 .result-card {
  color: white;
  border: 0.2px grey solid;
}
.search-form-V2 .movie-poster {
  max-width: 100px;
}
.search-form-V2 .card-title {
  color: white;
  font-weight: normal;
}
.search-form-V2 .suggestions {
  color: white;
  text-decoration: none;
  list-style-type: none;
  font-size: 1rem;
  cursor: pointer;
  padding-top: 10px;
}
.search-form-V2 .suggestions li:hover {
  color: red;
}
.search-form-V2 .suggestion-box {
  border: 1px solid grey;
  border-top: none;
  padding-left: 1rem;
  box-sizing: border-box;
}
.vt-modal .modal-content {
  background-color: var(--text-secondary-d30);
  min-height: 400px;
}
.vt-modal .modal-content .modal-header {
  border: none;
}
.vt-modal .modal-content .modal-body {
  padding: 2rem 0rem;
}
.vt-modal .modal-content .close {
  color: var(--accent-color);
}
.site-header {
  text-align: center;
  padding: 0 !important;
  border-bottom: 1px solid var(--text-secondary-a50);
  background: var(--header-color);
  width: 100%;
  transition: all 0.6s ease-out;
}
@media screen and (min-width: 992px) {
  .site-header {
    height: 75px;
  }
}
.site-header.scrolled {
  background: var(--background);
  min-height: 60px;
}
.site-header.scrolled .all-links {
  min-height: 60px;
}
.site-header .navbar-icon {
  position: relative;
  overflow: hidden;
}
.site-header .navbar-icon .vt-btn-like {
  position: relative;
}
.site-header .navbar-icon .vt-btn-like.vn-shake {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  -webkit-animation: shake 0.5s;
          animation: shake 0.5s;
  /* When the animation is finished, start again */
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.site-header .navbar-icon .shop-icon {
  width: 50px;
  height: 50px;
  padding: 8px;
  color: var(--text-primary);
  text-align: center;
  line-height: 1.4;
  font-size: 1.6rem;
  margin-right: 8px;
}
.site-header .navbar-icon .shop-item-count {
  line-height: 1.5;
  font-size: 0.7rem;
  font-weight: 600;
  position: absolute;
  text-align: center;
  width: 16px;
  height: 16px;
  background-color: var(--botton-color);
  border-radius: 50%;
  color: white;
  top: 5px;
  left: 21px;
}
.site-header .user-icon .vt.vt-usersolid {
  font-size: 30px;
  margin-left: 10px;
  color: var(--botton-color);
}
.site-header .user-profile-button .dd-name {
  display: none;
  color: var(--accent-color);
  padding: 0.25rem 0.4rem 0.5rem;
  margin-bottom: 6px;
  border-bottom: 1px solid var(--text-secondary-a50);
  cursor: default;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.site-header .user-profile-button .dd-name:hover {
  color: var(--accent-color);
}
.site-header .user-profile-button .dropdown-menu {
  width: 8rem;
  left: -6.7rem;
}
@media screen and (max-width: 992px) {
  .site-header .user-profile-button .dropdown-menu {
    width: auto;
  }
}
.site-header .user-profile-button .user-icon .user-name {
  font-size: 0.8rem;
  position: relative;
  bottom: 4px;
  color: var(--accent-color);
}
@media screen and (min-width: 992px) {
  .site-header .user-profile-button .user-icon .user-name {
    display: none;
  }
  .site-header .user-profile-button .dd-name {
    display: block;
  }
}
.site-header .explore-button {
  font-weight: 400;
}
.site-header .explore-button .vt.vt-browse {
  font-size: 2rem;
  margin-left: 5px;
  vertical-align: inherit;
}
.site-header .search-form {
  margin-top: 0;
}
.site-header .search-form form div.input-group:focus-within {
  width: 100%;
}
@media screen and (max-width: 992px) {
  .site-header .search-form {
    margin-top: 10px;
  }
  .site-header .search-form form div.input-group:focus-within {
    width: 80%;
  }
}
.site-header .search-form.suggestions {
  display: none;
}
.site-header .dropdown-menu {
  background-color: var(--header-color);
  text-align: center;
  top: 135%;
  left: -3.2rem;
}
@media screen and (min-width: 992px) {
  .site-header .dropdown-menu {
    width: 11.4rem;
  }
}
@media screen and (max-width: 992px) {
  .site-header .dropdown-menu {
    padding-top: 0;
    margin-top: 0;
  }
}
.site-header .dropdown-menu a,
.site-header .dropdown-menu span {
  color: var(--header-text-color);
  white-space: pre-wrap;
  cursor: pointer;
  font-size: 0.85rem;
  font-weight: 600;
  background: none;
  line-height: 1.2;
  margin-bottom: 0.2rem;
}
.site-header .dropdown-menu a:hover,
.site-header .dropdown-menu span:hover {
  color: var(--botton-color);
  background: none;
}
.site-header .dropdown-menu.sub-link {
  top: 156%;
}
.site-header .app-logo-lg {
  width: 200px;
}
@media screen and (max-width: 992px) {
  .site-header .app-logo-lg {
    width: 175px;
  }
}
@media screen and (max-width: 768px) {
  .site-header .app-logo-lg {
    width: 150px;
  }
}
.site-header .app-logo {
  height: 47px;
}
.site-header .navbar-toggler .navbar-toggler-icon {
  font-size: inherit;
  background: inherit;
}
.site-header .navbar-toggler .vt-menu:before {
  font-size: 30px;
  color: var(--header-text-color);
}
.site-header .all-links {
  transition: all 0.6s ease-out;
  min-width: 350px;
}
.site-header .all-links .nav-item {
  position: relative;
  float: left;
}
@media screen and (max-width: 992px) {
  .site-header .all-links .nav-item {
    margin-bottom: 5px;
  }
}
.site-header .all-links .nav-item .nav-link {
  font-size: 0.95rem;
  font-weight: 600;
  margin: auto 0;
  color: var(--header-text-color);
  line-height: 1.2;
}
.site-header .all-links .nav-item .nav-link.babylove {
  font-family: babylove;
  font-size: 1.3rem;
  letter-spacing: 0.15rem;
  line-height: 0.9;
  font-weight: 400;
}
@media screen and (max-width: 1200px) and (min-width: 992px) {
  .site-header .all-links .nav-item .nav-link.babylove {
    font-size: 1.1rem;
    line-height: 1;
  }
}
.site-header .all-links .nav-item .nav-link.true-link:hover:after, .site-header .all-links .nav-item .nav-link.true-link.active:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 50%;
  max-width: 50px;
  margin: auto;
  left: 25%;
  right: 25%;
  border-bottom: 3px solid var(--botton-color);
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translate(1px, 1px) rotate(0deg);
            transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
            transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
            transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(3px, 2px) rotate(0deg);
            transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
            transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
            transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
            transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(3px, 1px) rotate(-1deg);
            transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
            transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(1px, 2px) rotate(0deg);
            transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
            transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: translate(1px, 1px) rotate(0deg);
            transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
            transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
            transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(3px, 2px) rotate(0deg);
            transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
            transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
            transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
            transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(3px, 1px) rotate(-1deg);
            transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
            transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(1px, 2px) rotate(0deg);
            transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
            transform: translate(1px, -2px) rotate(-1deg);
  }
}
.site-header.earth {
  text-align: center;
  padding: 0;
  border-bottom: none;
  background: var(--header-color);
  width: 100%;
  transition: all 0.6s ease-out;
}
@media screen and (min-width: 992px) {
  .site-header.earth {
    height: 75px;
  }
}
.site-header.earth .navbar-brand {
  margin: 0;
}
.site-header.earth .navbar-icon {
  position: relative;
  overflow: hidden;
}
.site-header.earth .navbar-icon .vt-btn-like {
  position: relative;
}
.site-header.earth .navbar-icon .vt-btn-like.vn-shake {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  -webkit-animation: shake 0.5s;
          animation: shake 0.5s;
  /* When the animation is finished, start again */
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.site-header.earth .navbar-icon .shop-icon {
  width: 50px;
  height: 50px;
  padding: 8px;
  color: var(--text-primary);
  text-align: center;
  line-height: 1.4;
  font-size: 1.6rem;
  margin-right: 8px;
}
.site-header.earth .navbar-icon .shop-item-count {
  line-height: 1.5;
  font-size: 0.7rem;
  font-weight: 800;
  position: absolute;
  text-align: center;
  width: 16px;
  height: 16px;
  background-color: var(--header-text-color);
  border-radius: 50%;
  color: white;
  top: 5px;
  left: 21px;
}
.site-header.earth .user-icon .vt.vt-usersolid {
  font-size: 30px;
  margin-left: 10px;
}
.site-header.earth .user-profile-button .dropdown-menu {
  min-width: 8rem;
}
.site-header.earth .explore-button {
  font-weight: 400;
}
.site-header.earth .explore-button .vt.vt-browse {
  font-size: 2rem;
  margin-left: 5px;
  vertical-align: inherit;
}
.site-header.earth .search-form {
  margin-top: 0;
}
.site-header.earth .search-form form div.input-group:focus-within {
  width: 100%;
}
.site-header.earth .search-form .suggestions {
  display: none;
}
.site-header.earth .dropdown-menu {
  left: -2.8rem;
}
.site-header.earth .dropdown-menu a, .site-header.earth .dropdown-menu span {
  color: var(--text-secondary);
  cursor: pointer;
  font-weight: 800;
  font-size: 0.9rem;
}
.site-header.earth .dropdown-menu a:hover, .site-header.earth .dropdown-menu span:hover {
  color: var(--header-text-color);
}
.site-header.earth .app-logo {
  width: 150px;
  height: 64px;
}
@media screen and (max-width: 1200px) and (min-width: 992px) {
  .site-header.earth .app-logo {
    width: 117px;
    height: 50px;
  }
}
@media screen and (max-width: 768px) {
  .site-header.earth .app-logo {
    width: 117px;
    height: 50px;
  }
}
@media screen and (max-width: 576px) {
  .site-header.earth .app-logo {
    width: 94px;
    height: 40px;
  }
}
.site-header.earth .navbar-toggler {
  margin-right: 10px;
}
.site-header.earth .navbar-toggler .navbar-toggler-icon {
  font-size: inherit;
  background: inherit;
}
.site-header.earth .navbar-toggler .vt-menu:before {
  font-size: 30px;
  color: var(--header-text-color);
}
.site-header.earth .all-links {
  transition: all 0.6s ease-out;
  min-width: 252px;
}
@media screen and (max-width: 992px) {
  .site-header.earth .all-links {
    margin-bottom: 10px;
  }
  .site-header.earth .all-links.add-links {
    margin-bottom: 20px;
  }
}
.site-header.earth .all-links.add-links {
  display: flex;
  margin-left: auto;
  margin-right: 1rem;
  direction: ltr;
  padding: 0rem;
}
.site-header.earth .all-links.add-links .nav-item {
  float: right;
}
@media screen and (max-width: 992px) {
  .site-header.earth .all-links.add-links {
    display: flex;
  }
  .site-header.earth .all-links.add-links .nav-item {
    float: none;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    max-width: 250px;
  }
  .site-header.earth .all-links.add-links .nav-item .nav-link.vt-icon {
    margin: auto;
    width: 33px;
  }
}
.site-header.earth .all-links .nav-item {
  position: relative;
  float: left;
  margin-right: 8px;
  margin-left: 0;
}
.site-header.earth .all-links .nav-item .nav-link {
  margin: auto 0;
  color: var(--text-secondary);
  line-height: 1.2;
  font-weight: 800;
  font-size: 1.1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.site-header.earth .all-links .nav-item .nav-link:hover, .site-header.earth .all-links .nav-item .nav-link.active {
  color: var(--header-text-color);
}
.site-header.earth .all-links .nav-item .nav-link:hover:after, .site-header.earth .all-links .nav-item .nav-link.active:after {
  content: "";
  position: absolute;
  bottom: -20px;
  width: 86%;
  max-width: 86%;
  left: 7%;
  right: 7%;
  border-bottom: 3px solid var(--botton-color);
}
@media screen and (max-width: 992px) {
  .site-header.earth .all-links .nav-item .nav-link:hover:after, .site-header.earth .all-links .nav-item .nav-link.active:after {
    bottom: 2px;
    max-width: 58px;
    border-width: 2px;
    margin-left: auto;
    margin-right: auto;
  }
}
.site-header.earth .all-links .nav-item .nav-link.vt-icon:after, .site-header.earth .all-links .nav-item .nav-link.vt-btn-link:after {
  width: 0;
  height: 0;
  border: none;
}
.site-header.earth .all-links .nav-item .nav-link.vt-icon {
  padding: 0.1rem 0 0;
  margin: 0 0.4rem;
  width: 33px;
  height: 33px;
  min-width: 33px;
  min-height: 33px;
}
.site-header.earth .all-links .nav-item .nav-link.vt-icon .vt-search {
  font-size: 2.3rem;
}
.site-header.earth .all-links .nav-item .nav-link.vt-btn-link {
  margin-top: 5px;
  font-size: 0.7rem;
  padding: 0.4rem 0.4rem;
  font-weight: 600;
}
@media screen and (max-width: 1200px) and (min-width: 992px) {
  .site-header.earth .all-links .nav-item {
    margin-right: 3px;
  }
  .site-header.earth .all-links .nav-item .nav-link {
    font-size: 0.93rem;
  }
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translate(1px, 1px) rotate(0deg);
            transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
            transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
            transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(3px, 2px) rotate(0deg);
            transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
            transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
            transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
            transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(3px, 1px) rotate(-1deg);
            transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
            transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(1px, 2px) rotate(0deg);
            transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
            transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: translate(1px, 1px) rotate(0deg);
            transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
            transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
            transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(3px, 2px) rotate(0deg);
            transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
            transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
            transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
            transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(3px, 1px) rotate(-1deg);
            transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
            transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(1px, 2px) rotate(0deg);
            transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
            transform: translate(1px, -2px) rotate(-1deg);
  }
}
.vtn-page-loader-anim {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000000;
}
.vtn-page-loader-anim .anim-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--accent-color);
  -webkit-animation: animate 4s linear;
          animation: animate 4s linear;
}
.vtn-page-loader-anim .anim-line.over {
  background-color: var(--text-primary-a50);
}
@-webkit-keyframes animate {
  0% {
    width: 0%;
  }
  40% {
    width: 70%;
  }
  80% {
    width: 95%;
  }
  100% {
    width: 100%;
  }
}
@keyframes animate {
  0% {
    width: 0%;
  }
  40% {
    width: 70%;
  }
  80% {
    width: 95%;
  }
  100% {
    width: 100%;
  }
}
