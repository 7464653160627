@import "../../../scss/_base.scss";

body.mars {
	font-family: "Poppins", sans-serif !important;

	.vt-input,
	.plans-listing .plan-box,
	.vt-select {
		font-family: "Poppins", sans-serif;
	}

	.vt-btn {
		font-family: "Poppins", sans-serif;
		border-radius: 4px;
		font-weight: 600;

		font-size: 0.7rem;
		margin: 0 0.6rem 0 0;

		@include media-down(md) {
			min-width: 100px;
			font-size: 0.6rem;
		}

		&.btn-sm {
			font-size: 0.6rem;
			margin: 0;
			padding: 0.6rem 0.4rem;
			letter-spacing: 0rem;
		}

		&.vt-play {
			position: relative;
			padding-left: 2.3rem;

			&::before {
				position: absolute;
				font-size: 0.8rem;
				font-family: "Font Awesome 5 Free";
				top: 0.3rem;
				left: 1.2rem;
				content: "\f04b";
			}

			@include media-down(md) {
				padding-left: 2rem;

				&::before {
					font-size: 0.7rem;
					top: 0.35rem;
					left: 0.9rem;
				}
			}
		}
	}

	.widget-title h2 {
		font-size: 1.1rem;
		font-weight: 400;
		font-family: "Poppins", sans-serif !important;
		margin-bottom: 14px;
		margin-left: 0.2rem;
	}

	.hybrid-list-card-l2,
	.hybrid-list-card-l4 {
		.content-wrpr {
			.title {
				font-size: 0.9rem;
			}
		}
	}

	.navbar {
		// padding:100px;
		.user-profile-button {
			margin-right:0.4rem;
		}
		.search-form {
			margin-right: 1rem;
			min-width: 25rem;
			border-radius: 4px;
			@include media-down(xl) {
				min-width: 16rem;
			}
		}

		.search-box {
			border-radius: 4px !important;
		}

		.search-btn {
			border-radius: 4px;
		}
	}
	.site-footer{
		margin-top:3rem;
	}
	.register-form {
		.terms-text {
			color: $c-text-secondary;
		}
	}
}

//
