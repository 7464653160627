@import '../_base.scss';

body.venus {
	@media only screen and (min-width: 1100px) {
		.navbar .search-form {
			width: 300px;
		}
	}
	.hybrid-horizontal-listing .widget-title {
		color: $c-accent;
	}
	.hybrid-list-card-l3 .card-content .content-wrpr .title {
		font-size: 1.05rem;
		font-weight: 600;
	}
	.hybrid-grid-listing {
		.widget-title {
			margin-bottom: 30px;
			color: var(--text-secondary)
		}
	}
	.hybrid-details-card-l2 .left-col .description {
		color: var(--text-secondary);
		margin: 1rem 0;
		font-size: 0.85rem;
		line-height: 1.5;
	}
	.fb-google-login-button,.facebook-login-button {
		border-radius: 100px;
	}
}

