@import './_base.scss';

.shopping-cart-content {
	width: 100%;
	.widget-title {
		margin-bottom: 30px;
	}
	h2 {
		text-align: center;
	}
}
