@import '../_base.scss';

.site-header.earth {
	text-align: center;
	padding: 0;
	// height: 70px;
	// z-index: 99999;
	border-bottom: none;
	background: $c-header;

	width: 100%;
	$icon-sqr: 33px;
	// margin-bottom:40px;
	@include media-up('lg') {
		height: $header-height + px;
	}

	@include vt-transition(0.6s);

	.navbar-brand {
		margin: 0;
	}

	.navbar-icon {
		position: relative;
		overflow: hidden;
		.vt-btn-like {
			position: relative;
			&.vn-shake {
				/* Start the shake animation and make the animation last for 0.5 seconds */
				animation: shake 0.5s;
				/* When the animation is finished, start again */
				animation-iteration-count: infinite;
			}
		}
		.shop-icon {
			width: 50px;
			height: 50px;
			padding: 8px;
			color: $c-text-primary;
			text-align: center;
			line-height: 1.4;
			font-size: 1.6rem;
			margin-right: 8px;
		}
		.shop-item-count {
			line-height: 1.5;
			font-size: 0.7rem;
			font-weight: 800;
			position: absolute;
			text-align: center;
			width: 16px;
			height: 16px;
			background-color: $c-header-text-color;
			border-radius: 50%;
			color: white;
			top: 5px;
			left: 21px;
		}
	}

	.user-icon {
		.vt.vt-usersolid {
			font-size: 30px;
			margin-left: 10px; 
		}
	}

	.user-profile-button {
		.dropdown-menu {
			min-width:8rem;
		}
	}

	.explore-button {
		font-weight: 400;
		.vt.vt-browse {
			font-size: 2rem;
			margin-left: 5px; 
			vertical-align: inherit;
		}
	}

	.search-form {
		margin-top: 0;
		form{
			div.input-group {
				&:focus-within{
					 width: 100%;
				}
			} 
		}
	
		& .suggestions{
			display: none;
		}
	}

	.dropdown-menu {
		left: -2.8rem;
		
		a,span {
			color: $c-text-secondary;
			cursor: pointer;
			font-weight: 800;
    		font-size: 0.9rem;
			&:hover {
				color: $c-header-text-color;
				
			}
		}

	}

	.app-logo {
		width: 150px;
		height: 64px;
		@include media-down('xl') {
			@include media-up('lg') {
				width: 117px;
				height: 50px;
			}
		}
		@include media-down('md') {
			width: 117px;
			height: 50px;
		}
		@include media-down('sm') {
			width: 94px;
			height: 40px;
		}
	}
	
	.navbar-toggler {
		margin-right: 10px;
		.navbar-toggler-icon {
			font-size: inherit;
			background: inherit;
			// font-size: 40px;
		}
		.vt-menu:before {
			// padding-top: 10px;
			font-size: 30px;
			color: $c-header-text-color;
		}
	}
	
	.all-links {
		@include vt-transition(0.6s);
		min-width: 252px;
		@include media-down('lg') {
			margin-bottom: 10px;
			&.add-links {
				margin-bottom: 20px;
			}
		}
		
		&.add-links {
			display: flex;
			margin-left: auto;
			margin-right: 1rem;
			direction: ltr;
			padding:0rem;
			.nav-item {
				float: right;
			}
			@include media-down('lg') {
				display: flex;
				.nav-item {
					float: none;
					width: 80%;
					margin-left: auto;
					margin-right: auto;
					max-width: 250px;
					.nav-link.vt-icon {
						margin: auto;
						width: $icon-sqr;
					}
				}
			}
		}
		.nav-item {
			position: relative;
			float: left;
			margin-right: 8px;
			margin-left: 0;
			.nav-link {
				margin: auto 0;
				color: $c-text-secondary;
				line-height: 1.2;
				font-weight: 800;
				font-size: 1.1rem;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				&:hover, &.active {
					color: $c-header-text-color;
					&:after {
						content: "";
						position: absolute;
						bottom: -20px;
						width: 86%;
						max-width: 86%;
						left: 7%;
						right: 7%;
						border-bottom: 3px solid $c-botton-color;
						@include media-down('lg') {
							bottom: 2px;
							max-width: 58px;
							border-width: 2px;
							margin-left: auto;
							margin-right: auto;
						}
					}
				}
				&.vt-icon, &.vt-btn-link {
					&:after {
						width: 0;
						height: 0;
						border: none
					}
				}
				&.vt-icon {
					padding: 0.1rem 0 0;
					margin: 0 0.4rem;
					
					width: $icon-sqr;
					height: $icon-sqr;
					min-width: $icon-sqr;
					min-height: $icon-sqr;
					.vt-search {
						font-size: 2.3rem;
					}
				}
				&.vt-btn-link {
					margin-top: 5px;
					font-size: 0.7rem;
					padding: 0.4rem 0.4rem;
					font-weight: 600;
				}
			}
			@include media-down('xl') {
				@include media-up('lg') {
					margin-right: 3px;
					.nav-link {
						font-size: 0.93rem;
					}
				}
			}
		}
	}
}

@keyframes shake {
	0% { transform: translate(1px, 1px) rotate(0deg); }
	10% { transform: translate(-1px, -2px) rotate(-1deg); }
	20% { transform: translate(-3px, 0px) rotate(1deg); }
	30% { transform: translate(3px, 2px) rotate(0deg); }
	40% { transform: translate(1px, -1px) rotate(1deg); }
	50% { transform: translate(-1px, 2px) rotate(-1deg); }
	60% { transform: translate(-3px, 1px) rotate(0deg); }
	70% { transform: translate(3px, 1px) rotate(-1deg); }
	80% { transform: translate(-1px, -1px) rotate(1deg); }
	90% { transform: translate(1px, 2px) rotate(0deg); }
	100% { transform: translate(1px, -2px) rotate(-1deg); }
}