@import './_base.scss';

.cart-summary-card {
	color: $c-text-primary;
	font-size: 12px;
	margin-bottom: 40px;

	.summary-top-pad {
		padding-top: 10px;
	}
	.row {
		padding-bottom: 4px;
		&.summary-wrpr {
			padding-bottom: 15px;
		}
	}
	.summary-border {
		padding-bottom: 6px;
		margin-bottom: 8px;
		border-bottom: 1px solid $c-text-secondary;
	}
	
	.summary-title {
		h3 {
			font-weight: 600;
			font-size: 16px;
		}
	}

	.summary-right {
		text-align: right;
	}

	.summary-total {
		font-weight: 600;
	}

	.sh-cart-btn {
		height: 51px;
		min-height: 51px;
		
		.vt-btn {
			position: absolute;
		    right: 0;
		}
	}
}