@import './_base.scss';
.static-quantity { 
    margin-top: 3px;
    font-size: 0.9rem;
    color: rgba($c-text-primary, 0.7);
    
    .pr20{
        margin-right: 7px;
    }

    .qty-num{
        margin:0 8px;
        color: $c-text-primary;
    }
}