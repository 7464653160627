@import "../../scss/base.scss";

.vt-modal {
	.modal-content {
		background-color: $c-text-secondary-d30;
		min-height: 400px;

		.modal-header {
			border: none;
		}

		.modal-body {
			padding: 2rem 0rem;
		}

		.close {
			color: $c-accent;
		}
	}
}