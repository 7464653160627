@import '../../../scss/base.scss';


.jupiter .social-links {
	min-height: 100px;
	text-align: left;
	.all-links {
		padding-top: 15px;
		a.social-icon {
			margin-right: 34px;
		}
	}
	.app-logo {
		img {
			width: 200px;
			height: 51px;
		}
	}
	a.social-icon {
		margin-right: 10px;
		&::before {
			font-size: 2.4rem;
		}
		&.facebook::before {
			font-family: "Font Awesome 5 Brands";
			font-weight: 400;
			content: "\f082";
		}
		&.twitter::before {
			font-family: "Font Awesome 5 Brands";
			font-weight: 400;
			content: "\f081";
		}
		&.instagram::before {
			font-family: "Font Awesome 5 Brands";
			font-weight: 400;
			content: "\f16d";
		}
		&.youtube::before {
			font-family: "Font Awesome 5 Brands";
			font-weight: 400;
			content: "\f167";
		}
	}
	@include media-down('lg') {
		min-height: 80px;
		.all-links {
			min-height: 80px;
			padding-top: 13px;
			a.social-icon {
				margin-right: 20px;
				&::before {
					font-size: 2.1rem;
				}
			}
		}
		.app-logo {
			img {
				width: 180px;
				height: 46px;
			}
		}
		
	}
	@include media-down('md') {
		min-height: 60px;
		.all-links {
			min-height: 60px;
			padding-top: 10px;
			a.social-icon {
				float: left;
				margin-right: 15px;
				&::before {
					font-size: 1.8rem;
				}
			}
		}
		.app-logo {
			img {
				width: 160px;
				height: 41px;
			}
		}
	}
	@include media-down('sm') {
		.all-links a.social-icon {
			margin-right: 0;
			margin-right: 6px;
			// float: right;
		}
	}
}