@import "./base.scss";

.privacy_check {
	// display:none;
	position: fixed;
	bottom: 0px; 
	min-height: 70px;
	height:auto;
	width: 100%;
	background-color:$c-header;
	z-index:  6000;
	color:$c-header-text-color;

	.row {
		margin: 20px 5px;
	}
	.label-hldr {
		margin-top: 13px; 
	}
}

.earth .privacy_check {
	.label-hldr {
		margin-top: 4px; 
	}
}
