.vtn-cover-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
    opacity: 0;
    transition: opacity 0.5s linear;
    &.vt-vis {
        transition: opacity 0.5s linear 0.3s;
        opacity: 1;
    }
}