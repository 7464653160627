@import './_base.scss';

$cart-width: 500;
$close-icon-sqr: 18px;

.shopping-cart {
	top: 0;
	left: 0;
	position: fixed;
	z-index: 999;
	width: 100%;
	height: 100%;
	visibility: hidden;
	opacity: 0;
	transition: visibility 0.2s linear 0.5s, opacity 0.3s linear 0.5s;
	&.open {
		transition: visibility 0.2s linear, opacity 0.3s linear 0.5s;
		visibility: visible;
		opacity: 1;
		.cart-area {
			transition: right 0.5s ease-in 0.3s;
			right: 0;
		}
	}
	.cart-area {
		position: absolute;
		top: 0;
		right: -($cart-width + 5) + px;
		width: $cart-width + px;
		height: 100%;
		padding-top: $header-height + px;
		transition: right 0.5s ease-out;
	}
	.ca-wrpr {
		padding: 15px 10px 10px;
		width: 100%;
		height: 100%;
		border-left: 1px solid $c-text-secondary-f50;
		background: $c-header;
		overflow-y: auto;
	}
	.container {
		position: relative;
	}
	.close-icon {
		position: absolute;
		top:5px;
		left:10px;
		width: $close-icon-sqr;
		height: $close-icon-sqr;
		line-height: 1;
		color: $c-text-primary;
		font-size: 1.3rem;

		&:hover {
			color: $c-accent;
		}
	}

	
}
