@import "../../../scss/base.scss"; 

.vtn-page-loader-anim {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10000000;
	.anim-line{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 2px;
		background-color: $c-accent;
		animation: animate 4s linear;
		&.over {
			background-color: $c-text-primary-f50;
		}
	}
	@keyframes animate { 
		0% { 
			width: 0%; 
		} 

		40% { 
			width: 70%;
		}

		80% { 
			width: 95%;
		}

		100% { 
			width: 100%;
		}
	}
}