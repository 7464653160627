@import './_base.scss';

.empty-cart-card {
	.empty-cart-msg {
		text-align: center;
		color: $c-text-primary;
		font-weight: 600;
	}
	.empty-cart-grf {
		width: 300px;
		height: 300px;
		padding: 15px;
		margin: 30px auto;
	}
	.cart-links {
		margin-top: 15px;
		.cart-links-msg {
			text-align: center;
			color: $c-text-secondary;
			font-size: 12px;
		}
		a, a:hover {
			color: $c-text-secondary;
		}
	}
}