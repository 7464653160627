@import './_base.scss';

.search-form {
	// margin-top: 0px !important;
	form {
		div.input-group {
			width: 90%;
			margin: auto;
			
			-webkit-transition: width 0.4s linear;
			transition: width 0.4s linear;

			%search-box-items-cm {
				background-color: $c-background;
				border-radius: 50px;
				color: $c-text-primary;
				&:focus {
					box-shadow: none;
				}
			}
			&.in-page {
				width: 50%;
				@include media-down(lg) {
					width: 70%;
				}
				@include media-down(md) {
					width: 85%;
				}
			}
			@include media-down(lg) {
				width: 70%;
			}
			
			.search-box {
				@extend %search-box-items-cm;
				border: 1px solid $c-text-secondary;
				border-radius: 30px;
				height: 36px;
				font-size: 0.85rem;
				padding-right: 36px;
				@include media-up(md) {
					margin-top:5px;
					height: 41px;
				}
			}
			
			.search-button {
				@extend %search-box-items-cm;
				height: 34px;
				position: absolute;
				z-index: 3;
				top: 1px;
				right: 5px;
				padding: 0 10px 0 2px;
				margin: 0;
				@include media-up(md) {
					height: 39px;
					top: 6px;
				}

				.vt.vt-search {
					font-size: 33px;
					@include media-up(md) {
						font-size: 38px;
					}
				}
			}
			.search-list {
				width: 100%;
			}
		}
	}
	.suggestions{
		text-align: center;
		list-style-type: none;
		color: $c-text-primary;

		.widget-title {
			color: $c-accent;
			h2 {
			  font-size: 1.5rem;
			  font-family: 'Montserrat';
			}
		}
	}
}



.search-form-V2{
	.container{
		max-width: 40%;
		margin: 0 auto;
		margin-top: 8rem;
	  }
	  
	  .search-bar{
		min-width: 76%;
		height: 36px;
		border:none;
		background-color: rgb(52, 52, 52);
		text-align: justify;
		padding-left: 15px;
		color: white;
		outline: none;
	  }
	  
	  .search-btn{
		width: 26% ;
		height: 38px;
		margin-left: -29px;
		border: none;
		background-color: rgb(187, 61, 139);
		color: white;
		font-weight: bold;
		outline: none;
	  }
	  .search-btn:hover{
		background-color: rgb(167, 46, 121);
	  }
	  .search-bar:hover{
		background-color: rgb(59, 58, 58);
	  }
	  
	  .search-card{
		color: rgb(255, 255, 255);
		text-underline-offset: 1px solid white;
		font-weight: 10;
		padding-top: 10px;
		padding-left: 20px;
		border-bottom: 1px solid white;
		max-width: 90;
	  }
	  
	  .result-card{
		color:white;
		border: 0.2px grey solid;
	  }
	  .movie-poster{
		max-width: 100px;
	  }
	  
	  .card-title{
		color:white;
		font-weight: normal;
	  }
	  
	  .suggestions{
		color:white;
		text-decoration: none;
		list-style-type: none;
		font-size: 1rem;
		cursor: pointer;
		padding-top: 10px;
	  }
	  .suggestions li:hover{
		color: red;
	  }
	  
	  .suggestion-box{
		border: 1px solid grey;
		border-top: none;
		padding-left: 1rem;
		box-sizing: border-box;
	  }
}

