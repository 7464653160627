@import "../base.scss";

.earth .site-footer {
	background-color:$c-header;
	border: none;
	text-align: left;
	margin-top: 30px;
	.widget-title h2 {
		margin-left: 0;
	}
	.bottom-line {
		border-bottom: 2px solid $c-text-secondary-f50;
	}
	.all-links {
		min-height: 70px;
	}
	.all-links a {
		margin-right: 24px;
	}
	.container {
		padding: 30px 30px 10px;
	}
	.all-links .nav-item{
		margin: 0;
		padding: 0;
		text-decoration: none;
	}
	@include media-down('lg') {
		.container {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	.icon-link{
		&::before {
			display: inline-block;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			font-family: "Font Awesome 5 Free"; 
			font-weight: 800; 
			font-size: 1rem;
			content: "\f007";
			margin-right: 8px;
    		position: relative;
    		top: 2px;
		}
	}
	//for smaller sizes
	@include media-down('md') {
		.all-links,
		.all-links a {
			font-size: 0.75rem;
		}
		.all-links {
			min-height: 50px;
			
		}
		.widget-title h2{
			font-size: 1rem;
			margin-bottom: 10px;
    		margin-left: 7px;
		}
	}
	@include media-down('sm') {
		.widget-title h2{
			font-size: 0.9rem;
			margin-bottom: 7px;
    		margin-left: 5px;
		}
		.all-links a {
			font-size: 0.65rem;
		}
	}
}