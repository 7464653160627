@import '../_base.scss';

.limited-viewing-popup {
	top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
	z-index: 998;
	display:flex;
	align-items:center;
	justify-content:center;
	.message-box {
		$width: 90%;
		$height: 90%;
		$max-width: 500px;
		width: $width;
		max-width: $max-width;
		height: $height;
		max-height: (9 / 16 * $max-width);
		background-color: $c-background-f50;
		border: 1px solid $c-text-secondary-f50;
		border-radius: 7px;
		display:flex;
		align-items:center;
		justify-content:center;
		z-index: 999;
		img {
			width: 100%
		}
		.text-wrp {
			width: 100%;
		}
		h2 {
			margin-top: 20px;
			color: $c-text-primary;
			margin-bottom: 40px;
			font-size: 1.2rem;
			padding: 0 5%;
		}
		.btns {
			height: 40px;
			margin-bottom: 15px;
			width: 100%;
			.btn {
				margin-left: 10px;
			}
		}
		@include media-down(lg) {
			h2 {
				font-size: 1.1rem;
				margin-bottom: 35px;
			}
			.btns {
				height: 37px;
				.btn {
					margin-left: 9px;
				}
			}
		}
		@include media-down(md) {
			h2 {
				font-size: 0.95rem;
				margin-bottom: 25px;
			}
			.btns {
				height: 34px;
				.btn {
					margin-left: 8px;
				}
			}
		}
		@include media-down(sm) {
			h2 {
				font-size: 0.75rem;
				margin-bottom: 20px;
			}
			.btns {
				height: 31px;
				.btn {
					margin-left: 7px;
				}
			}
		}
	}
}